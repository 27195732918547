import Color from 'color';
import merge from 'lodash/merge';

import { media } from '@stories/theming/settings';
import { ASSET_URL } from '@utils/constants';

import defaultTheme from './default';

export const fontUrls = [
  `${ASSET_URL}/asset/fonts/Yanone_Kaffeesatz/YanoneKaffeesatz-VariableFont_wght.woff2`,
  `${ASSET_URL}/asset/fonts/PTSans/PTSans-Regular.woff2`,
  `${ASSET_URL}/asset/fonts/PTSans/PTSans-Bold.woff2`
];

const fontFaceAccent = `@font-face {
  font-family:"shelby";
  src:url("https://use.typekit.net/af/de68cc/00000000000000007735b41f/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/de68cc/00000000000000007735b41f/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/de68cc/00000000000000007735b41f/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
  font-display:swap;font-style:normal;font-weight:400;font-stretch:normal;
}`;

//  src: url(/asset/fonts/Yanone_Kaffeesatz/YanoneKaffeesatz-VariableFont_wght.woff2) format('woff2-variations');

const fontFaceHeadline = `@font-face {
  font-family: 'Yanone Kaffeesatz';
  src: url(${ASSET_URL}/asset/fonts/Yanone_Kaffeesatz/YanoneKaffeesatz-VariableFont_wght.woff2) format('woff2 supports variations'), url(${ASSET_URL}/asset/fonts/Yanone_Kaffeesatz/YanoneKaffeesatz-VariableFont_wght.woff2) format('woff2-variations');
  font-weight: 200 700;
  font-display: block;
}`;

const fontFaceCopy = `@font-face {
  font-family: 'PT Sans';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url(${ASSET_URL}/asset/fonts/PTSans/PTSans-Regular.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'PT Sans';
  font-style: normal;
  font-weight: 700;
  font-display: block;
  src: url(${ASSET_URL}/asset/fonts/PTSans/PTSans-Bold.woff2) format('woff2');
}
`;

const baseColors = {
  primary: 'hsl(153, 37%, 31%)',
  secondary: 'hsl(18, 100%, 12%)',
  copy: 'hsl(0, 0%, 15%)',
  grey: {
    g92: 'hsl(0, 0%, 92%)',
    g97: 'hsl(0, 0%, 97%)',
    g60: 'hsl(0, 0%, 60%)',
    g40: 'hsl(0 0% 40%)'
  }
};

const color = {
  ...baseColors,
  primaryDark: 'hsl(153, 37%, 19%)',
  primaryBackground: baseColors.primary,
  highlight: baseColors.primary,
  accent: 'hsl(44 100% 70%)',
  outline: 'hsl(39, 79%, 68%)',
  hover: {
    copy: baseColors.primary,
    highlight: 'hsl(73, 60%, 43%)',
    primary: 'hsl(153, 37%, 43%)'
  }
};

const font = {
  family: {
    headline: "'Yanone Kaffeesatz', sans-serif",
    copy: "'PT Sans', sans-serif",
    accent: "'Shelby', sans-serif"
  },
  size: {
    copy: '18px',
    small: '16px',
    footnote: '14px'
  },
  face: {
    headline: fontFaceHeadline,
    copy: fontFaceCopy,
    accent: fontFaceAccent
  },
  copy: {
    font: 'normal 18px/140% "PT Sans", sans-serif'
  },
  small: {
    font: 'normal 16px/140% "PT Sans", sans-serif'
  },
  footnote: {
    font: 'normal 14px/140% "PT Sans", sans-serif'
  },
  tiny: {
    font: 'normal 12px/140% "PT Sans", sans-serif'
  },
  link: {
    textDecoration: 'underline',
    textDecorationColor: 'hsl(0, 0%, 50%)',
    textUnderlineOffset: '3px',
    ':hover': {
      color: color.primary,
      textDecorationColor: 'currentColor'
    }
  }
};

const headline = {
  h1: {
    font: '400 48px/110% "Yanone Kaffeesatz", sans-serif',
    fontKerning: 'none',
    fontFeatureSettings: '"cpsp" on, "liga" off',
    letterSpacing: '0.04em',
    textTransform: 'uppercase',
    [media('desktop')]: {
      fontSize: '64px'
    }
  },
  h2: {
    font: '400 32px/110% "Yanone Kaffeesatz", sans-serif',
    letterSpacing: '0.02em',
    textTransform: 'uppercase',
    [media('desktop')]: {
      fontSize: '36px'
    }
  },
  h3: {
    font: '400 32px/110% "Yanone Kaffeesatz", sans-serif',
    letterSpacing: '0.02em',
    [media('desktop')]: {
      fontSize: '36px'
    }
  },
  h4: {
    font: '400 28px/110% "Yanone Kaffeesatz", sans-serif',
    letterSpacing: '0.02em',
    [media('desktop')]: {
      fontSize: '32px'
    }
  },
  h5: {
    font: 'bold 18px/120% "PT Sans", sans-serif',
    letterSpacing: '0.02em',
    textTransform: 'uppercase',
    [media('desktop')]: {
      fontSize: '20px'
    }
  }
};

const buttonCommonStyle = {
  borderRadius: '8px',
  letterSpacing: '0.05em',
  textTransform: 'uppercase' as const
};

export const button = {
  primary: {
    solid: {
      ...buttonCommonStyle,
      backgroundColor: color.primary,
      border: `1px solid ${color.primary}`,
      color: 'white',
      '@media (hover: hover)': {
        '&:hover': {
          backgroundColor: Color(color.primary).darken(0.05).hsl().string(),
          borderColor: 'white'
        }
      }
    },
    outline: {
      ...buttonCommonStyle,
      backgroundColor: 'transparent',
      border: `1px solid ${color.primary}`,
      color: color.primary,
      '@media (hover: hover)': {
        '&:hover': { backdropFilter: 'invert(.08)' }
      }
    }
  },
  secondary: {
    solid: {
      ...buttonCommonStyle,
      backgroundColor: color.secondary,
      border: `1px solid ${color.secondary}`,
      color: 'white',
      '@media (hover: hover)': {
        '&:hover': {
          backgroundColor: Color(color.secondary).lighten(0.5).hsl().string(),
          borderColor: 'white'
        }
      }
    },
    outline: {
      ...buttonCommonStyle,
      backgroundColor: 'transparent',
      border: `1px solid ${color.secondary}`,
      color: color.secondary,
      '@media (hover: hover)': {
        '&:hover': { backdropFilter: 'invert(.08)' }
      }
    }
  },
  tertiary: {
    solid: {
      ...buttonCommonStyle,
      backgroundColor: 'white',
      border: `1px solid white`,
      color: color.primary,
      '@media (hover: hover)': {
        '&:hover': {
          backgroundColor: Color(color.grey.g92).darken(0.05).hsl().string(),
          borderColor: 'white'
        }
      }
    },
    outline: {
      ...buttonCommonStyle,
      backgroundColor: 'transparent',
      border: '1px solid white',
      color: 'white',
      '@media (hover: hover)': {
        '&:hover': { backdropFilter: 'invert(.08)' }
      }
    }
  },
  small: {
    compensateY: '2px',
    font: '500 20px/18px "Yanone Kaffeesatz", sans-serif',
    letterSpacing: '0.05em',
    padding: '9px 1.5rem'
  },
  regular: {
    compensateY: '3px',
    font: '500 24px/20px "Yanone Kaffeesatz", sans-serif',
    letterSpacing: '0.05em',
    padding: '10px 1.5rem'
  },
  big: {
    compensateY: '3px',
    font: '500 24px/25px "Yanone Kaffeesatz", sans-serif',
    letterSpacing: '0.05em',
    padding: '.75rem 2.25rem'
  }
};

const header = {
  burgerColor: color.secondary,
  color: color.secondary,
  navigation: {
    container: {
      ...font.footnote,
      fontSize: '13px',
      color: color.grey.g40,
      lineHeight: '1.5rem'
    },
    firstLevelItems: {
      fontWeight: 'bold',
      lineHeight: '1.75rem',
      textTransform: 'uppercase'
    },
    secondLevelItems: {
      ':hover': {
        color: color.primary
      },
      fontWeight: 'bold',
      lineHeight: '1.75rem',
      textTransform: 'uppercase'
    },
    thirdLevelItems: {
      ':hover': {
        color: color.primary
      }
    }
  },
  searchInput: {
    backgroundColor: 'hsl(19 8% 93%)',
    backdropFilter: 'blur(10px)',
    borderRadius: '8px',
    color: 'black',
    overflow: 'hidden'
  },
  topBar: {
    backgroundColor: 'hsl(0 0% 15% / 0.9)',
    color: 'white',
    font: font.small.font
  }
};

const footer = {
  backgroundColor: color.primary
};

const theme: typeof defaultTheme = merge({}, defaultTheme, {
  storeId: 'vomachterhof',
  border: {
    border: `thin solid ${color.grey.g92}`,
    borderRadius: '8px'
  },
  button,
  font,
  headline,
  badge: {
    core: {
      alignItems: 'center',
      border: 'thin solid transparent',
      borderRadius: '0.25rem',
      display: 'inline-block',
      fontSize: '11px',
      fontWeight: 'bold',
      justifyContent: 'center',
      lineHeight: '100%',
      padding: '.125rem 0.5rem',
      textTransform: 'uppercase'
    },
    new: {
      backgroundColor: 'hsl(41 100% 44%)',
      borderColor: 'hsl(41 100% 44%)',
      color: 'white'
    },
    bestseller: {
      backgroundColor: 'hsl(73 60% 34%)',
      borderColor: 'hsl(73 60% 34%)',
      color: 'white'
    },
    discount: {
      backgroundColor: 'hsl(0 100% 36%)',
      borderColor: 'hsl(0 100% 36%)',
      color: 'white'
    },
    freeShipping: {
      backgroundColor: 'hsl(18 100% 12%)',
      borderColor: 'hsl(18 100% 12%)',
      color: 'white'
    },
    freeText: {
      backgroundColor: 'hsl(41 100% 44%)',
      borderColor: 'hsl(41 100% 44%)',
      color: 'white'
    }
  } as const,
  color,
  header,
  footer
});

export default theme;
