/** @jsxImportSource @emotion/react */
import { type Interpolation, type Theme, useTheme } from '@emotion/react';
import toInteger from 'lodash/toInteger';
import Image from 'next/image';
import { useContext, useEffect, useRef, useState } from 'react';

import useTranslate from '@common/hooks/useTranslate';
import { sendGAEvent } from '@common/utlis';
import Button from '@stories/atoms/Button';
import useAddToCart from '@stories/atoms/Button/useAddToCart';
import Select from '@stories/atoms/FormElements/Select/Select';
import IconClose from '@stories/atoms/Icons/IconClose.svg';
import { zIndexes } from '@stories/atoms/utils';
import { PageContext } from '@stories/templates/Context/pageContext';
import { media, mediaDown } from '@stories/theming/settings';
import {
  ProductWithOptionsContext,
  type TProductOptions
} from '@stories/utlis/context/ProductWithOptionsContext';
import { getProductConfiguration } from '@utils/helpers';

import type { ProductType } from '@stories/organisms/HitListView/ReduceProps';

type TProductConfigurationOverlayProps = {
  uncofiguredProductOptions: TProductOptions;
  header: string;
  product: {
    productId: string;
    productImage: string;
    name?: string;
    price?: string;
    baseprice?: string;
    type?: ProductType;
  };
  visible?: boolean;
};
const containerClass: Interpolation<Theme> = {
  margin: '0 -1rem',
  overflow: 'auto',
  padding: '0 1rem 1.5rem'
};
const ctaClass: Interpolation<Theme> = {
  borderTop: 'solid thin var(--color-border-grey)',
  boxShadow: '0 0 10px -3px hsl(0 0% 0% / 20%)',
  margin: '0 -1rem',
  padding: '1rem',
  [media('desktop')]: {
    borderTop: 0,
    boxShadow: 'none'
  }
} as const;

const configOverlayStyle: Interpolation<Theme> = {
  alignItems: 'stretch',
  display: 'flex',
  flexDirection: 'column',
  bottom: 0,
  maxHeight: '90vh',
  width: '100vw',
  borderTopLeftRadius: '16px',
  borderTopRightRadius: '16px',
  justifyContent: 'center',
  padding: '4rem 1rem',
  [mediaDown('desktop')]: {
    backgroundColor: 'white',
    borderRadius: '14px 14px 0 0',
    padding: '1.5rem var(--offset) 0',
    width: '100vw',
    zIndex: zIndexes.darkOverlay + 1
  },
  [media('desktop')]: {
    position: 'fixed',
    margin: 'auto',
    bottom: '50%',
    transform: 'translateY(50%)',
    backgroundColor: 'white',
    borderRadius: '14px',
    padding: '1.5rem 1.5rem 1rem',
    maxHeight: '60vh',
    maxWidth: '460px',
    zIndex: zIndexes.darkOverlay + 1
  }
} as const;

const translations = {
  'Add to cart': {
    de_DE: 'In den Warenkorb'
  }
};

const ProductConfigurationOverlay: React.FC<TProductConfigurationOverlayProps> = ({
  uncofiguredProductOptions,
  product,
  visible = false
}) => {
  const t = useTranslate(translations);
  const theme = useTheme();
  const { hideOverlay } = useContext(PageContext);
  const containerRef = useRef<HTMLDivElement>(null);

  const [configuredProductOptions, setConfiguredProductOptions] = useState(uncofiguredProductOptions);
  const { isLoading, handleAddProductClick } = useAddToCart();

  const { productId, name, price, baseprice, type, productImage } = product || {};

  const onSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    // TODO add to cart already tracked in useAddToCart
    sendGAEvent({ category: 'Product configuration overlay', action: 'add to cart', label: productId });

    const productConfiguration = getProductConfiguration(configuredProductOptions);
    handleAddProductClick({
      productId,
      productConfiguration,
      productType: type
    }).finally(() => {
      hideOverlay();
    });
  };

  const changeOption = ({ productOptionId, selectedOptionId }) =>
    setConfiguredProductOptions([
      ...configuredProductOptions?.map((productOption) => {
        if (productOption.id === productOptionId) {
          return {
            ...productOption,
            selection: productOption?.selection?.map((selectOption) => ({
              ...selectOption,
              selected: selectOption.id === selectedOptionId
            }))
          };
        }
        return productOption;
      })
    ]);

  const handleChange = (e) => {
    const selectedIndex = parseInt(e.target.selectedIndex);
    const selectedId = parseInt(e?.target?.options[selectedIndex]?.value);
    const elementId = toInteger(e.target.getAttribute('select_element_id'));

    sendGAEvent({
      category: 'Product configuration overlay',
      action: 'select changed',
      label: `{"productId": ${productId}, "selectedId": ${selectedId}, "elementId": ${elementId}}`
    });

    changeOption({ productOptionId: elementId, selectedOptionId: selectedId });
  };

  useEffect(() => {
    const productOptionsWithSelectAttribute = uncofiguredProductOptions?.map((productOption) => {
      const firstInStockIndex = productOption.selection.findIndex(
        (selectOption) => selectOption.is_in_stock === true
      );

      return {
        ...productOption,
        selection: productOption.selection.map((selectOption, idx) => ({
          ...selectOption,
          selected: idx === firstInStockIndex ? true : false
        }))
      };
    });

    setConfiguredProductOptions(productOptionsWithSelectAttribute);
  }, [uncofiguredProductOptions]);

  useEffect(() => {
    const currentRef = containerRef.current;

    const handleScroll = () => {
      if (currentRef) {
        currentRef.removeEventListener('scroll', handleScroll);
      }

      sendGAEvent({
        category: 'Product configuration overlay',
        action: 'scrolled',
        label: `{"productId": ${productId}}`
      });
    };

    if (currentRef) {
      currentRef.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (currentRef) {
        currentRef.removeEventListener('scroll', handleScroll);
      }
    };
  }, [containerRef, productId]);

  return (
    <ProductWithOptionsContext.Provider
      value={{
        baseprice: baseprice || '',
        name: name || '',
        price: price || '',
        productId,
        productImage,
        productOptions: configuredProductOptions,
        setProductOptions: () => {},
        productType: type
      }}
    >
      <form
        method="POST"
        css={(t) => ({
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          fontSize: t.font.size.small,
          justifyContent: 'center',
          position: 'fixed',
          left: 0,
          bottom: 0,
          opacity: 0,
          transform: 'translateY(120%)',
          transition: 'transform 0.2s ease-in, opacity 0.2s ease-in',
          zIndex: zIndexes.darkOverlay + 1,
          ...(visible && {
            opacity: 1,
            transform: 'translateY(0)',
            transition: 'transform 0.3s 0.3s ease-out, opacity 0s 0s'
          })
        })}
        onSubmit={onSubmit}
      >
        <div
          css={(t) => ({
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
            flexDirection: 'column',
            fontFamily: t.font.family.copy
          })}
        >
          <div
            css={{
              ...configOverlayStyle,
              transform: 'translate(0%)'
            }}
          >
            <div
              css={(t) => ({
                fontSize: t.font.size.copy,
                marginBottom: '2rem',
                paddingRight: '2rem'
              })}
            >
              <h2 css={{ fontWeight: 'bold', fontSize: theme.font.size.large }}>
                Wähle einen Geschmack aus:
              </h2>
            </div>

            <div
              css={{
                display: 'grid',
                gap: '0 0.5rem',
                gridTemplate: `
                  "image text1" 
                  / 70px auto
                `,
                gridTemplateRows: 'min-content auto',
                fontSize: theme.font.size.footnote,
                lineHeight: '125%',
                marginBottom: '.5rem'
              }}
            >
              <Image
                src={productImage}
                alt={name ?? ''}
                width="60"
                height="60"
                css={{ gridArea: 'image', width: '60px', height: 'auto' }}
              />
              <div css={{ gridArea: 'text1', opacity: '0.8' }}>
                <div>{name}</div>
                {price}&nbsp;
                <span
                  dangerouslySetInnerHTML={{ __html: `${baseprice}` || '' }}
                  css={(t) => ({ fontSize: t.font.size.tiny, opacity: 0.75, fontWeight: 400 })}
                />
              </div>
            </div>

            <div css={containerClass} ref={containerRef}>
              <IconClose
                css={{ position: 'absolute', top: '14px', right: '13px' }}
                onClick={() => {
                  hideOverlay();
                }}
              ></IconClose>
              {uncofiguredProductOptions?.map((element) => (
                <div css={{ padding: '0.5rem 0' }} key={element.id}>
                  <div css={{ display: 'flex', flexDirection: 'column' }}>
                    <Select
                      options={element?.selection.map((option) => ({
                        ...option,
                        disabled: !option.is_in_stock,
                        value: option.id.toString(),
                        label: option.title ?? ''
                      }))}
                      cssProps={{
                        select: {
                          width: '100%'
                        }
                      }}
                      attrs={{ select_element_id: element.id }}
                      onChange={handleChange}
                    ></Select>
                  </div>
                </div>
              ))}
            </div>

            <div css={ctaClass}>
              <Button
                type="submit"
                label={`${t('Add to cart')}`}
                isLoading={isLoading}
                size={'big'}
                cssProps={{
                  margin: 'auto',
                  maxWidth: '420px',
                  width: '100%'
                }}
              ></Button>
            </div>
          </div>
        </div>
      </form>
    </ProductWithOptionsContext.Provider>
  );
};

export default ProductConfigurationOverlay;
