import { createContext, useContext } from 'react';

import { formatPrice } from '@common/utlis';
import { FORMAT_CURRENCY_OPTIONS } from '@utils/constants';

import type { StoreData } from '@utils/hygraph';
import type { TTrustedShopsRatingResponse } from '@vitafy/storefront-api-contracts';
import type { CategoryListItemFromDBDto } from '@vitafy/storefront-api-contracts-fetch';
import type { ShowProductConfiguration } from 'src/components/layout';

type PageContextType = {
  categoryTree: CategoryListItemFromDBDto[];
  cinematicMode: boolean;
  etrustedData?: TTrustedShopsRatingResponse | null;
  hideOverlay: () => void;
  isFilterOverlayShown: boolean;
  isNavigationShown: boolean;
  isNotificationProductShown: boolean;
  numberOfLinesShownInProductHit: number;
  setFilterOverlayShown: (x: boolean) => void;
  setNavigationShown: (x: boolean) => void;
  setNotificationProductShown: (x: boolean) => void;
  showProductConfiguration: ShowProductConfiguration;
  showOverlay: ({
    callback,
    closeOnClick,
    disableBackButton
  }: {
    callback: () => void;
    closeOnClick?: boolean;
    disableBackButton?: boolean;
  }) => void;
  storeCode: string;
  storeId: number;
  currentCategoryPath?: number[];
  centeredSearchBar?: boolean;
  desktopNavigationNumberOfColumns: number;
  logo?: StoreData['logo'];
  storeData: {
    activateWishlist?: boolean;
    categoryHeroFallbackImage?: string;
    promoBannerCmsBlock?: string;
  };
  formatPrice: (price: string, options) => string | number;
};

const defaultValues = {
  categoryTree: [],
  cinematicMode: false,
  hideOverlay: () => {
    return;
  },
  etrustedData: undefined,
  showProductConfiguration: () => {
    return;
  },
  numberOfLinesShownInProductHit: 3,
  isFilterOverlayShown: false,
  isNavigationShown: false,
  isNotificationProductShown: false,
  setFilterOverlayShown: () => {
    return;
  },
  setNavigationShown: () => {
    return;
  },
  setNotificationProductShown: () => {
    return;
  },
  showOverlay: () => {
    return;
  },
  storeCode: '',
  storeId: 0,
  centeredSearchBar: false,
  desktopNavigationNumberOfColumns: 3,
  storeData: {},
  formatPrice: () => ''
};

const PageContext = createContext<PageContextType>(defaultValues);

export const usePageContext = () => {
  const context = useContext(PageContext);
  if (!context) {
    throw new Error('PageContext must be used within a PageContext.Provider');
  }
  return {
    ...context,
    formatPrice: (price, options?) => {
      const defaultOptions = {
        ...(FORMAT_CURRENCY_OPTIONS[context.storeCode] || FORMAT_CURRENCY_OPTIONS['vomachterhof']),
        maximumFractionDigits: 2
      };

      return formatPrice(price, { ...defaultOptions, ...options });
    }
  };
};

export { PageContext };
