import debounce from 'lodash/debounce';
import { useEffect, useState } from 'react';

import { useResizeListener } from '@common/hooks/useResizeListener';

interface Size {
  width: number | undefined;
  height: number | undefined;
}

const handleResize = debounce((setWindowSize) => {
  setWindowSize({
    width: window?.visualViewport?.width,
    height: window?.visualViewport?.height
  });
}, 100);

export default function useWindowSize(): Size {
  const [windowSize, setWindowSize] = useState<Size>({
    width: undefined,
    height: undefined
  });

  useResizeListener(() => handleResize(setWindowSize));

  useEffect(() => {
    handleResize(setWindowSize);
  }, []);

  return windowSize;
}
