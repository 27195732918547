/** @jsxImportSource @emotion/react */

import { keyframes, useTheme } from '@emotion/react';
import { useRouter } from 'next/router';
import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useRef } from 'react';

import { sendGAEvent } from '@common/utlis';
import { flyoutCssFn } from '@stories/atoms/Flyout/Flyout';
import { LinkSwitched } from '@stories/atoms/Link';
import MediaLink from '@stories/molecules/MediaLink';
import { PageContext } from '@stories/templates/Context/pageContext';

import { noScrollbarStyle, visuallyHidden } from '../../../common/cssUtilities';

import type { CategoryListItemFromDBDto } from '@vitafy/storefront-api-contracts-fetch';

/* HEADER DESKTOP NAVIGATION
 * Page header navigation for Desktop only. First level items are displayed in an
 * horizontal, scrollable bar, just below the header. On hover, a flyout is displayed,
 * where sub and sub-sub categories are presented.
 */

type FlyoutSubcategoriesType = {
  categoryItems: Array<CategoryListItemFromDBDto>;
  getCallbacksForCategory: (categoryId: number) => { onMouseEnter: () => void; onMouseLeave: () => void };
};

const HeaderNavigationContext = createContext<{
  cinematicMode: boolean;
  flyoutShownForCategoryId: undefined | number;
}>({
  cinematicMode: false,
  flyoutShownForCategoryId: undefined
});

const hasArrayItems = (children) => (children?.length ? children.length > 0 : false);

const slideInKeyframes = keyframes`
  0% {
    opacity: 0;
    transform-origin: top center;
    scale: 0.98;
  }
  100% {
    opacity: 1;
    scale: 1;
  }
`;

type RegularSubcategoryBlock = {
  child: CategoryListItemFromDBDto['children'][0];
};

const RegularSubcategoryBlock: React.FC<RegularSubcategoryBlock> = ({ child }) => {
  const { desktopNavigationNumberOfColumns, storeCode } = useContext(PageContext);
  const theme = useTheme();
  const flyoutColumns = desktopNavigationNumberOfColumns ?? 3;

  return (
    <div key={child.id} css={{ flex: `${100 / flyoutColumns}% 0 0`, padding: '.5rem' }}>
      {child.url.includes('magazin') ? (
        <a href={`/${child.url}`} css={theme.header.navigation.secondLevelItems}>
          {child.name} MAGAZINE
        </a>
      ) : (
        <LinkSwitched
          scroll={false}
          pathname={`/[store]/${child.type}/[categoryId]`}
          query={{ store: storeCode, categoryId: child.id }}
          href={`/${child.url}`}
          cssProps={theme.header.navigation.secondLevelItems}
          attrs={{
            'data-category-id': child.id,
            onClick: () =>
              sendGAEvent({
                category: 'Navigation',
                action: `level-${child.path.length - 2}_click`,
                label: child.name
              })
          }}
        >
          {child.name}
        </LinkSwitched>
      )}
      {hasArrayItems(child.children) && (
        <ul>
          {child.children.map((grandchild) => (
            <li key={grandchild.id}>
              <LinkSwitched
                scroll={false}
                pathname={`/[store]/${grandchild.type}/[categoryId]`}
                query={{ store: storeCode, categoryId: grandchild.id }}
                href={`/${grandchild.url}`}
                cssProps={theme.header.navigation.thirdLevelItems}
                attrs={{
                  'data-category-id': grandchild.id,
                  onClick: () =>
                    sendGAEvent({
                      category: 'Navigation',
                      action: `level-${grandchild.path.length - 2}_click`,
                      label: grandchild.name
                    })
                }}
              >
                {grandchild.name}
              </LinkSwitched>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

const FlyoutSubcategories: React.FC<FlyoutSubcategoriesType> = ({
  categoryItems,
  getCallbacksForCategory
}) => {
  const { cinematicMode, flyoutShownForCategoryId } = useContext(HeaderNavigationContext);
  const [isFlyoutShown, setIsFlyoutShown] = useState(false);

  useEffect(() => {
    const hasActiveCategorySubcategories =
      categoryItems.find(
        (item) => item.id === flyoutShownForCategoryId && item.children && item.children.length > 0
      ) !== undefined;

    setIsFlyoutShown(flyoutShownForCategoryId !== undefined && hasActiveCategorySubcategories);
  }, [flyoutShownForCategoryId, categoryItems]);

  return (
    <div
      css={(t) => ({
        ...flyoutCssFn(t),
        ...(cinematicMode && {
          backgroundColor: 'hsl(0 0% 100% / 90%)',
          backdropFilter: 'blur(20px)',
          border: 'none',
          color: 'hsl(0deg 0% 0% / 60%)'
        }),
        ...(isFlyoutShown ? { animation: `${slideInKeyframes} 0.3s ease-in-out` } : { ...visuallyHidden }),
        boxShadow: '0 0 24px -12px hsl(0 0% 0% / 7%), 0 14px 45px -25px hsl(0 0% 0% / 12%)',
        left: '50%',
        marginTop: '.5rem',
        position: 'absolute',
        transform: 'translateX(-50%)',
        width: '100%'
      })}
    >
      {categoryItems.map(({ children, id }) => (
        <div
          {...getCallbacksForCategory(id)}
          key={id}
          css={{
            display: 'flex',
            flexWrap: 'wrap',
            padding: '1rem 0 2rem',
            transform: 'translateY(-.5rem)', // fire mouseEnter when hovering the top margin
            width: '100%',
            ...(isFlyoutShown &&
              id !== flyoutShownForCategoryId && {
                ...visuallyHidden
              })
          }}
        >
          {children.map((child) =>
            child.type === 'CMSMediaLink' ? (
              <div css={{ flex: '25% 0 0', padding: '0 .5rem' }} key={child.name}>
                <MediaLink {...child} />
              </div>
            ) : (
              <RegularSubcategoryBlock key={child.id} child={child} />
            )
          )}
        </div>
      ))}
    </div>
  );
};

export type TNavigationProps = {
  categoryTree: CategoryListItemFromDBDto[];
  cinematicMode?: boolean;
  cssProps?: object;
};

const Navigation: React.FC<TNavigationProps> = ({ categoryTree, cssProps, cinematicMode = false }) => {
  const [flyoutShownForCategoryId, setFlyoutShownForCategoryId] = useState<number | undefined>(undefined);
  const { currentCategoryPath } = useContext(PageContext) || {};
  const [hoverUnderlinePosition, setHoverUnderlinePosition] = useState<
    undefined | { left: number; width: number }
  >(undefined);
  const theme = useTheme();
  const router = useRouter();
  const { storeCode } = useContext(PageContext);
  const timeoutIdRef = useRef<NodeJS.Timeout | null>(null);

  const getCallbacksForCategory = useCallback((categoryId: number) => {
    const handleMouseEnter = () => {
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
      }
      setFlyoutShownForCategoryId(categoryId);
    };

    const handleMouseLeave = () => {
      timeoutIdRef.current = setTimeout(() => {
        setFlyoutShownForCategoryId(undefined);
        timeoutIdRef.current = null;
      }, 300);
    };

    return {
      onMouseEnter: handleMouseEnter,
      onMouseLeave: handleMouseLeave
    };
  }, []);

  useEffect(() => {
    router.events.on('routeChangeComplete', () => {
      setFlyoutShownForCategoryId(undefined);
    });
  }, [router]);

  useEffect(() => {
    const highlightedCategoryId =
      flyoutShownForCategoryId || (currentCategoryPath ? currentCategoryPath[2] : undefined);
    if (!highlightedCategoryId) {
      setHoverUnderlinePosition(undefined);
      return;
    }

    const element = document.querySelector(`[data-category-id="${highlightedCategoryId}"]`);
    if (element) {
      const placeUnderline = () => setHoverUnderlinePosition(element.getBoundingClientRect());
      document.fonts.ready.then(placeUnderline);
    }
  }, [currentCategoryPath, flyoutShownForCategoryId]);

  return (
    <HeaderNavigationContext.Provider value={{ cinematicMode, flyoutShownForCategoryId }}>
      <nav data-testid="desktop-navigation" css={(t) => ({ ...t.header.navigation.container, ...cssProps })}>
        <div css={() => ({ position: 'relative', zIndex: '10000' })}>
          <div
            css={(t) => ({
              ...noScrollbarStyle,
              margin: 'auto',
              maxWidth: t.section.width.regular,
              overflow: 'auto',
              padding: '0 .25rem',
              textAlign: 'center',
              whiteSpace: 'nowrap',
              width: '100%',
              ...(cinematicMode && { color: 'white' })
            })}
          >
            {categoryTree.map(({ id, name, type, url }) => (
              <LinkSwitched
                forceAnchorLink={url.includes('magazin')}
                key={id}
                href={`/${url}`}
                pathname={`/[store]/${type}/[categoryId]`}
                query={{ store: storeCode, categoryId: id }}
                attrs={{
                  ...getCallbacksForCategory(id),
                  'data-category-id': id,
                  onClick: () => {
                    sendGAEvent({
                      category: 'Navigation',
                      action: `level-0_click`,
                      label: name
                    });
                  }
                }}
                cssProps={{
                  borderBottom: '3px solid transparent',
                  display: 'inline-block',
                  ...theme.header.navigation.firstLevelItems,
                  margin: '0 .75rem'
                }}
              >
                {name}
              </LinkSwitched>
            ))}
          </div>
        </div>
        <div
          css={() => ({
            position: 'relative',
            width: '100%'
          })}
        >
          <FlyoutSubcategories
            categoryItems={categoryTree}
            getCallbacksForCategory={getCallbacksForCategory}
          />
        </div>
        {hoverUnderlinePosition && (
          <div
            css={{
              backgroundColor: cinematicMode ? 'hsl(0 0% 94%)' : theme.color.hover.copy,
              bottom: cinematicMode ? 2 : 0,
              height: cinematicMode ? '2px' : '3px',
              left: 0,
              position: 'absolute',
              transform: `translateX(${hoverUnderlinePosition.left}px)`,
              transition: 'transform 0.225s ease-in-out, width 0.225s ease-in-out',
              width: hoverUnderlinePosition.width
            }}
          />
        )}
      </nav>
    </HeaderNavigationContext.Provider>
  );
};

export default Navigation;
